import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import _ from "lodash";
import moment from "moment";
import navbarLogo from "../Images/Synops-navbar-logo.svg";
import i18n from "i18next";
import "../MonthlyOperationalReview/CaseStudy.css";
import parse from 'react-html-parser';

const ExtractSrcLink = (Link) => {
  const DocsearchTerm = 'src="';
  const VideoImagesearchTerm = "?";
  const Extensions = [".mp4", ".mov", ".webm", ".mkv", ".ogv", ".3g2", ".jpeg", ".jpg", ".giff", ".png"];
  let isDoc;
  if (Extensions.some((v) => Link.toUpperCase().includes(v.toUpperCase()))) {
    isDoc = false;
    return `${Link.split(VideoImagesearchTerm)[0]}`;
  } else {
    if (Link.includes("<iframe")) {
      isDoc = true;
      return `${Link.split(DocsearchTerm)[1].split('"')[0]}`;
    } else {
      return Link;
    }
  }
};

export default class PulseMorCaseStudyDocViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MORItemDetails: {},
      DocDetails: {},
      allData: [],
      ImageExtensions: [".jpeg", ".jpg", ".giff", ".png"],
      VideoExtensions: [".mp4", ".mov", ".webm", ".mkv", ".ogv", ".3g2"],
      minshow: false,
      isCaseStudy: false,
      isShowDocViewer: false,
      currentIndex: null,
      isAdmin: false,
    };
  }
  

  componentWillMount() {
    let { DocDetails,isShowDocViewer, isCaseStudy, 
        ViewerItemDeatils, AllViewerItemDeatils
    } = this.props;

    let indexID = null;
    if (isCaseStudy) {
      this.setState({
        isCaseStudy,
        DocDetails: { ...DocDetails },
        currentIndex:0,
        isShowDocViewer,
      });
    } else {
      AllViewerItemDeatils.map((obj, index) => {
        if (obj.MORItemID === ViewerItemDeatils.ItemId) {
          indexID = index;
        }
      });
      this.setState({
        isCaseStudy,
        allData: AllViewerItemDeatils,
        MORItemDetails: { ...ViewerItemDeatils },
        currentIndex: indexID,
        isShowDocViewer,
      });
    }
  }
  onClose = () => {
    this.setState({
      isShowDocViewer: false,
    });
    this.props.closeViewer();
  };
  leftCaseStudy = () => {
    const { currentIndex,MORItemDetails, isCaseStudy, allData } = this.state;
    if (currentIndex != null) {
      if (currentIndex != 0) {
        let pos = currentIndex - 1;
        let current = allData[pos];
        if (!isCaseStudy) {
          MORItemDetails.ItemId = current.MORItemID;
          MORItemDetails.ItemName = current.ItemName;
          MORItemDetails.ItemLink = current.ItemLink;
          this.setState({
            MORItemDetails,
            currentIndex: pos,
          });
        }
      }
    }
  };
  rightCaseStudy = () => {
    const { currentIndex,MORItemDetails, isCaseStudy, allData } = this.state;

    if (currentIndex != null) {
      if (currentIndex !== allData.length - 1) {
        let pos = currentIndex + 1;
        let current = allData[pos];
        if (!isCaseStudy) {
          MORItemDetails.ItemId = current.MORItemID;
          MORItemDetails.ItemName = current.ItemName;
          MORItemDetails.ItemLink = current.ItemLink;
          this.setState({
            MORItemDetails,
            currentIndex: pos,
          });
        }
      }
    }
  };
  minimize = (e, item) => {
    const { ImageExtensions, VideoExtensions } = this.state;
    if (item !== "") {
      if (VideoExtensions.some((v) => item.includes(v))) {
        document.getElementsByTagName("ReactPlayer")[0].className = "";
      } else if (ImageExtensions.some((v) => item.includes(v.toUpperCase()))) {
        document.getElementById("imageMaximize").className = "";
      } else {
        document.getElementById("docMaximize").className = "";
      }
    } else {
      document.getElementsByTagName("iframe")[0].className = "";
    }
    this.setState({ minshow: false });
  };
  Maximize = (e, item) => {
    const { ImageExtensions, VideoExtensions } = this.state;
    if (item !== "") {
      if (VideoExtensions.some((v) => item.includes(v))) {
        document.getElementsByTagName("ReactPlayer")[0].className = "fullScreen";
      } else if (ImageExtensions.some((v) => item.includes(v.toUpperCase()))) {
        document.getElementById("imageMaximize").className = "fullScreen";
      } else {
        document.getElementById("docMaximize").className = "fullScreen";
      }
    } else {
      document.getElementsByTagName("iframe")[0].className = "fullScreen";
    }
    this.setState({ minshow: true });
  };


RenderSpecificElement=(sourceURL,Iframe)=>{
  const {
    ImageExtensions,
    VideoExtensions,
  } = this.state;

if(VideoExtensions.some((v) => sourceURL.toUpperCase().includes(v.toUpperCase()))){
  return  <ReactPlayer width="100%" height="500px" controls url={ExtractSrcLink(sourceURL)} />
}
else if(ImageExtensions.some((v) => sourceURL.toUpperCase().includes(v.toUpperCase()))){
  return <img
  className="maxminimage"
  src={ExtractSrcLink(sourceURL)}
  id="imageMaximize"
  alt="client title"
  width="100%"
  height="500px"
/>
}
else if(sourceURL !== undefined &&  sourceURL.includes(".pdf")){
return <div className="pdflinkadded">
<a target="_blank" href={ExtractSrcLink(sourceURL)}>
Click here to view your file
</a>
</div>
}
else{
  return  (Iframe && typeof Iframe === 'string' ?<div>{parse(Iframe)}</div> : null)
}
}

  render() {
    const {
      DocDetails,
      MORItemDetails,
      isShowDocViewer,
      ImageExtensions,
      VideoExtensions,
      isCaseStudy,
      isAdmin,
    } = this.state;
    const Iframe = `<iframe id="docMaximize" src=${
      isCaseStudy ? 
      ExtractSrcLink(DocDetails.URL) : MORItemDetails ? 
      ExtractSrcLink(MORItemDetails.ItemLink) : ""
    } width="100%" 
  height="500px" frameborder="0"></iframe>`;
  
    return (
      <>
        <Modal
          show={isShowDocViewer}
          onHide={this.onClose}
          className="addcaseStudy-modal casestudy-detailmodal morviewer-modal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title className="casestudy-title">
              <img loading="lazy" src={navbarLogo} alt="Synops-logo" width="24" height="24" />{" "}
              {isCaseStudy ? "Case Study" : MORItemDetails ? MORItemDetails.ItemName : ""}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="casestudycard-content morMaximize">
              <div className="mor-maximise">
             
                {isCaseStudy &&
                VideoExtensions.some(
                  (v) =>
                    DocDetails.URL.toUpperCase().includes(v.toUpperCase()) 
                   
                ) ? (
                  ""
                ) : (
                    DocDetails.URL !== undefined && DocDetails.URL.includes(".pdf") ?"":
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={(e) => this.Maximize(e, isCaseStudy ? DocDetails.URL : MORItemDetails.ItemLink)}
                  >
                    {i18n.t("Maximize")}
                    <i class="fas fa-compress-arrows-alt"></i>
                  </button> 
                )}
              </div>
              <div className="casestudy-modalimg">
                {this.state.minshow ? (
                  <div class="close-excel" onClick={(e) => this.minimize(e, isCaseStudy ? DocDetails.URL : MORItemDetails.ItemLink)}>
                    <i class="far fa-window-minimize"></i>
                  </div>
                ) : (
                  ""
                )}
                {isCaseStudy ? this.RenderSpecificElement(DocDetails.URL,Iframe):this.RenderSpecificElement(MORItemDetails.ItemLink,Iframe)}
                {
                    this.state.allData.length >1 && <div className="casestudy-controllers">
                    <span className={this.state.currentIndex === 0 ?"left-controller" :"left-controller active"}  onClick={this.leftCaseStudy}>
                      <i class="fal fa-angle-left"></i>
                    </span>
                    <span className={this.state.currentIndex === (this.state.allData.length - 1) ? "right-controller" : "right-controller active"}  onClick={this.rightCaseStudy}>
                      <i class="fal fa-angle-right"></i>
                    </span>
                  </div>
                }
                
              </div>
              {isCaseStudy ? (
                <div className="csupdated-info common-ellipsis-dropdown">
                  <p>
                    <span>
                      <span className="lastupdated-on">
                      {i18n.t("Last_updated_on")}{" "}
                      {moment
                        .utc(DocDetails.UpdatedTS)
                        .local()
                        .format("MMMM DD, YYYY")}
                        </span>
                      &nbsp;|&nbsp;<span>{i18n.t("File_uploaded_by")}</span>{" "}
                      <span className="email-ids">{DocDetails.UpdatedBy}@accenture.com</span>
                    </span>
                  </p>
                </div>
              ) : (
                <div className="csupdated-info common-ellipsis-dropdown">
                  <p>
                    <span>
                      <span className="lastupdated-on">
                      {i18n.t("Last_updated_on")}{" "}
                      {moment
                        .utc(MORItemDetails.UpdatedTS)
                        .local()
                        .format("MMMM DD, YYYY")}
                        </span>
                      &nbsp;|&nbsp;<span>{i18n.t("File_uploaded_by")}</span>{" "}
                      <span className="email-ids">{MORItemDetails.CreatedBy}@accenture.com</span>
                    </span>
                  </p>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
